import React from 'react';
import {LoginForm} from "../../components/session";
import {Link} from 'gatsby';
import {navigateTo} from "gatsby-link";
import * as Paths from '../../constants/Paths';
import LayoutA from "../../layouts/LayoutA";
import {Button} from '@blueprintjs/core'
import {getGoogleAuthRedirect} from '../../api/SessionApi';

class LoginPage extends React.Component {

  render() {
    return (
      <LayoutA>
        <div className="LoginTemplate">
          <div className="LoginTemplate-floatingform">
            <h1 className="LoginTemplate-title">Log In</h1>
            <div className="LoginTemplate-oauthbuttons">
              <Button type="submit"
                      className="s2"
                      intent="primary"
                      text="Sign In With Google"
                      onClick={() => getGoogleAuthRedirect().then((result) => window.location = result.url )}
                      large={true}
              />
            </div>
            <div className="LoginTemplate-separator">or</div>
            <LoginForm onSuccess={() => navigateTo(Paths.DB_USER_HOME)}/>
            <div className="LoginTemplate-buttonbar">
              <Link to={Paths.REGISTER} className="f4">
                <span className="pt-icon-standard pt-icon-user"/>
                Register a new account
              </Link>
              <Link to={Paths.FORGOT_PASSWORD} className="f4">
                <span className="pt-icon-standard pt-icon-key"/>
                Reset Password
              </Link>
            </div>
          </div>
        </div>
      </LayoutA>
    );
  }
}

export default LoginPage;